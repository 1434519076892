import React, { useState } from "react"
import { useEffect } from 'react'

import WebPage from "./_webpage"
import Header from "../components/header"
import RiceCarousel from "../components/molecules/rice-carousel"
import Footer from "../components/footer"

import { useCookies } from "react-cookie"
import { Button, Col, Modal, Row } from "react-bootstrap"

import * as httpServices from '../services/http-services'
import Layout3 from "./layouts/layout-3"
import Layout4 from "./layouts/layout-4"
import Layout7 from "./layouts/layout-7"
import Layout2 from "./layouts/layout-2"
import Layout12 from "./layouts/layout-12"
import Layout13 from "./layouts/layout-13"
import Layout10 from "./layouts/layout-10"
import Layout9 from "./layouts/layout-9"
import Layout5 from "./layouts/layout-5"
import Layout1 from "./layouts/layout-1"
import Layout8 from "./layouts/layout-8"
import Layout6 from "./layouts/layout-6"
import Layout11 from "./layouts/layout-11"

const Home = () => {

  const [cookies, setCookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])

  const [slide, setSlide] = useState([])
  const [mainContent, setMainContent] = useState([])
  const [entersite, setEntersite] = useState({})
  const [show, setShow] = useState(false);

  const handleClose = () => {
    checkShowEntersite()
    setShow(false)
  }

  const checkShowEntersite = () => {
    var tomorrow = new Date();
    var today = new Date();
    tomorrow.setDate(today.getDate() + 1);
    setCookies('showEntersite', false, { path: '/', expires: tomorrow });
  }

  useEffect(() => {
    if (cookies.subdomain !== undefined) {
      httpServices.getEntersite(cookies.subdomain).then(response => {
        if (response.data.success) {
          setEntersite(response.data.data)
          // if (!cookies.showEntersite) {
          response.data.data.ets_show === 0 ? setShow(false) : setShow(true)
          // }
        }
      })
    }

    httpServices.getMainPageSlide(cookies.subdomain).then(response => {
      if (response.data.success) {
        setSlide(response.data.data)
      }
    })
  }, [cookies.subdomain])

  useEffect(() => {
    if (cookies.subdomain !== undefined) {
      httpServices.getAllMainContent(cookies.subdomain).then(response => {
        if (response.data.success) {
          setMainContent(response.data.data)
        }
      })
    }
  }, [cookies.subdomain, cookies.subdomain])

  return (
    <>
      <Modal show={show} onHide={handleClose} fullscreen={true}>
        <Modal.Body className="d-flex align-items-center justify-content-center entersite-box" style={{ backgroundColor: entersite.ets_bg_color }}>
          <Row>
            <Col>
              <img src={entersite.ets_img} className="entersite-img" />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center" >
          {(entersite.ets_bt_other !== 0) && <Button variant="primary" onClick={() => window.open(entersite.ets_bt_link, '_blank')}>
            {entersite.ets_bt_text}
          </Button>}
          <Button variant="primary" onClick={handleClose}>
            เข้าสู่เว็บไซต์
          </Button>
        </Modal.Footer>
      </Modal>
      <WebPage mainComponents={
        <>
          <Header title={'กรมการข้าว | Rice Department'} description={'เว็บไซต์กรมการข้าว กระทรวงเกษตรและสหกรณ์'} />
          <div>
            <RiceCarousel datas={slide} />
            {
              mainContent?.map((mc, index) => {
                if (mc.mc_type === 1) {
                  // แบบมีหัวข้อย่อย
                  return (
                    <Layout1 key={'layout' + index + mc['mc_id']} content={mc} />
                  )
                } else if (mc.mc_type === 2) {
                  // แบบมีหัวข้อย่อยแบบใหญ่
                  return (
                    <Layout2 key={'layout' + index + mc['mc_id']} content={mc} />
                  )
                } else if (mc.mc_type === 3) {
                  // แบบไม่มีขอบเว้นระยะ
                  return (
                    <Layout3 key={'layout' + index + mc['mc_id']} content={mc} />
                  )
                } else if (mc.mc_type === 4) {
                  // แบบไม่มีขอบไม่เว้นระยะ
                  return (
                    <Layout4 key={'layout' + index + mc['mc_id']} content={mc} />
                  )
                } else if (mc.mc_type === 5) {
                  // แบบมีขอบเว้นระยะ
                  return (
                    <Layout5 key={'layout' + index + mc['mc_id']} content={mc} />
                  )
                } else if (mc.mc_type === 6) {
                  // แบบมีขอบไม่เว้นระยะ
                  return (
                    <Layout6 key={'layout' + index + mc['mc_id']} content={mc} />
                  )
                } else if (mc.mc_type === 7) {
                  // ไม่มีชื่อหัวข้อแบบชนิดมีหัวข้อย่อย
                  return (
                    <Layout7 key={'layout' + index + mc['mc_id']} content={mc} />
                  )
                } else if (mc.mc_type === 8) {
                  return (
                    <Layout8 key={'layout' + index + mc['mc_id']} content={mc} />
                  )
                } else if (mc.mc_type === 9) {
                  return (
                    <Layout9 key={'layout' + index + mc['mc_id']} content={mc} />
                  )
                } else if (mc.mc_type === 10) {
                  return (
                    <Layout10 key={'layout' + index + mc['mc_id']} content={mc} />
                  )
                } else if (mc.mc_type === 11) {
                  return (
                    <Layout11 key={'layout' + index + mc['mc_id']} content={mc} />
                  )
                } else if (mc.mc_type === 12) {
                  // ไม่มีชื่อหัวข้อแบบมีขอบไม่เว้นระยะ
                  return (
                    <Layout12 key={'layout' + index + mc['mc_id']} content={mc} />
                  )
                } else if (mc.mc_type === 13) {
                  return (
                    <Layout13 key={'layout' + index + mc['mc_id']} content={mc} />
                  )
                }
              })
            }
            <img
              src={require('../assets/images/allrice_app.png')}
              width="100%"
              className="section-box"
            />
          </div>
          <Footer type={'main'} />
        </>
      } />
    </>
  )
}

export default Home
