import React, { useState, useEffect } from "react";
import { Container } from 'react-bootstrap';
import { useCookies } from "react-cookie";
import * as httpServices from '../../services/http-services';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';


SwiperCore.use([Navigation, Pagination]);

const Content3Swipe = ({ paId }) => {
  const [cookies, setCookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])
;
  const [details, setDetails] = useState([]);

  useEffect(() => {
    httpServices.getAllMainContentDetail(cookies.subdomain, 3, paId).then(response => {
      if (response.data.success) {
        setDetails(response.data.data.content);
      }
    });
  }, [paId, cookies.subdomain]);

  return (
    <Container>
      <Swiper
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        spaceBetween={10}
        breakpoints={{
          768: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 5,
          },
        }}
      >
        {details?.map((data) => (
          <SwiperSlide key={'pb_' + data.pb_id}>
            <a href={data.pb_link} target="_blank">
              <img className='link-img-card' src={data.pb_pic} alt={`Slide ${data.pb_id}`} />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  )
}

export default Content3Swipe;
