import React, { useState } from "react"
import { useEffect } from 'react'
import { useCookies } from "react-cookie"
import * as httpServices from '../../services/http-services'
import { Row, Tab, Tabs } from "react-bootstrap"
import Content2 from "./content-2"


const Content7 = ({ paId, page = 1, num, isPagination = false }) => {

    const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])

    const [details, setDetails] = useState([])

    useEffect(() => {
        httpServices.getAllMainContentDetail(cookies.subdomain, 7, paId, page, num).then(response => {
            if (response.data.success) {
                setDetails(response.data.data.content)
            }
        })
    }, [cookies.subdomain])

    return (
        <Tabs
            id="justify-tab-example"
            className="mb-3"
            justify
        >
            {
                details?.map((tab, index) => {
                    return (
                        <Tab key={tab['pc_id']} eventKey={tab['pc_id']} title={tab['pc_title_' + cookies.language] ?? ""}>
                            <Row className='news-card-section'>
                                {<Content2 num={8} isPagination={isPagination} paId={tab['pc_doc']} />}
                            </Row>
                        </Tab>
                    )
                })
            }
        </Tabs>
    )
}

export default Content7
