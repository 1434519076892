import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCloudDownload, faEye, faPen } from '@fortawesome/free-solid-svg-icons'
import { useCookies } from "react-cookie"
import lang from '../../config/translate.json'
import { capitalizeEachFirstLetter } from '../../utils'
import dayjs from "dayjs"


const DetailContentDoc = ({ data, stats }) => {

    const [cookies, setCookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])


    return (
        <div>
            <div className={"breadcrumb-box-page-free"}>
                <Container className="breadcrumb-text-box">
                    <a href={"/"}>{lang.home[cookies.language]}</a> / <a>{data.content && data.content['pa_name_' + cookies.language]}</a>
                </Container>
            </div>
            <Container className='page-header-box'>
                <Row className='news-section-header-box'>
                    <Col>
                        <div>
                            <h2 className='news-section-header-with-line'>{data.detail && capitalizeEachFirstLetter(data.detail['title_' + cookies.language])}</h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className='news-thumbnail-box'>
                        <p className='pr-news-thumbnail-attribute'>
                            <FontAwesomeIcon size="xs" icon={faCalendar} className='text-normal-grey' /> <strong>{dayjs(data.content.pd_date).add(7,'hour').format("DD/MM/YYYY HH:MM")}</strong>  &nbsp;&nbsp;
                            <FontAwesomeIcon size="xs" icon={faEye} className='text-normal-grey' /> <strong>{stats['AllVisit']}</strong>
                        </p>
                        <p>{data.content && data.content['pd_description_' + cookies.language]}</p>
                        <div dangerouslySetInnerHTML={{ __html: data.content && data.content['pd_detail_' + cookies.language] }} />
                    </Col>
                </Row>
                <Row>
                    {
                        data.docs?.map((doc) => {
                            return (
                                <Col xs="3" key={'doc_' + doc.doc_id} className="doc-download">
                                    <FontAwesomeIcon size="xs" icon={faCloudDownload} className='text-normal-grey' />  <a href={doc.doc_url}>{lang.download[cookies.language]}</a>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}

export default DetailContentDoc
