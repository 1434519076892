import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"

import 'bootstrap/dist/css/bootstrap.min.css'

import { CookiesProvider } from 'react-cookie'
import { HelmetProvider } from 'react-helmet-async';
import SubdomainProvider from './providers/subdomain-provider'

import Home from './pages/home'
import Empty from './pages/empty'
import Page from './pages/page'
import Category from './pages/category'
import Page404 from './pages/404'
import AddressPage from './pages/main/address'
import FormPage from './pages/main/form'
import PersonnelPage from './pages/main/personnel'
import ItaPage from './pages/main/ita'
import ItaSubPage from './pages/main/ita-sub'
import AllLinkPage from './pages/main/all_link'
import FormAppealPage from './pages/main/form-appeal'

const helmetContext = {};

ReactDOM.render(
  <CookiesProvider>
    <SubdomainProvider>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:page" element={<Page />} />
            {/* <Route path="/empty" element={<Empty />} /> */}
            <Route path="/404" element={<Page404 />} />
            <Route path="/ita" element={<ItaPage />} />
            <Route path="/ita-sub/:id" element={<ItaSubPage />} />
            <Route path="/address" element={<AddressPage />} />
            <Route path="/qanda" element={<FormPage />} />
            <Route path="/appeal" element={<FormAppealPage />} />
            <Route path="/personnel" element={<PersonnelPage />} />
            <Route path="/all_link" element={<AllLinkPage />} />
            {/* <Route path="/search/:keyword" element={<Page />} /> */}
            <Route path="/page/:pid" element={<Page />} />
            <Route path="/category/:type" element={<Category />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </SubdomainProvider>
  </CookiesProvider>,
  document.getElementById('root')
)
