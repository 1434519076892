import React, { useEffect, useState } from "react";
import { Col, Row, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faCalendarDays, faCalendarWeek, faChartColumn, faEnvelope, faFax, faHouse, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { useCookies } from 'react-cookie';
import lang from '../config/translate.json'
import * as httpServices from '../services/http-services'

const Footer = ({ type, id, statisticsCallback }) => {

    const [cookies, setCookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])
    const [acceptedCookie, setAcceptedCookie] = useState(cookies.cookie_accepted)
    const [footer_menu, setFooterMenu] = useState([])
    const [social_link, setSocialLink] = useState({})
    const [address, setAddress] = useState({})
    const [statistics, setStatistics] = useState({})

    useEffect(() => {
        const acceptedCookie = cookies.cookie_accepted;
        if (!acceptedCookie) {
            setAcceptedCookie(false);
        }
        getAddress();
        getMenu();
        getSocialNetWork();
        if (!(type === '404' || type === 'ita' || type === 'ita-sub' || type === 'form')) {
            if (cookies.siteId != undefined) {
                httpServices.getPageDetail(cookies.siteId, type, id).then(response => {
                    if (response.data.success) {
                        let page_id = response.data.data.page_id
                        httpServices.postCounter({ page_id: page_id, site_id: cookies.siteId }).then((response) => {
                            if (response.data.success) {
                                setStatistics(response.data.data)
                                if (statisticsCallback) {
                                    statisticsCallback(response.data.data)
                                }
                            }
                        });
                    }
                })
            }
        }
    }, [cookies.subdomain, cookies.siteId])

    const getMenu = () => {
        httpServices.getFooterMenu(cookies.subdomain)
            .then(response => {
                if (response.data.success) {
                    let list_menu = []
                    let data = response.data.data;
                    data.map((item) => {
                        list_menu.push({
                            "mnm_id": item.mnm_id,
                            "site_id": item.site_id,
                            "mnm_type": item.mnm_type,
                            "mnm_name_TH": item.mnm_name_TH,
                            "mnm_name_EN": item.mnm_name_EN,
                            "mnm_link": item.mnm_link,
                            "mnm_priority": item.mnm_priority,
                            "default_status": item.default_status,
                            "sub_menu": item.sub_menu
                        })
                    })
                    setFooterMenu(list_menu);
                }
            })
    }

    const getSocialNetWork = () => {
        httpServices.getSocialNetWork(cookies.subdomain)
            .then(response => {
                if (response.data.success) {
                    let data = response.data.data;
                    setSocialLink(data);
                }
            })
    }

    const getAddress = () => {
        httpServices.getWebAddress(cookies.subdomain)
            .then(response => {
                if (response.data.success) {
                    let data = response.data.data;
                    setAddress(data);
                }
            })
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    const handleAcceptCookie = () => {
        var tomorrow = new Date();
        var today = new Date();
        tomorrow.setDate(today.getDate() + 1);
        setCookies('cookie_accepted', true, { path: '/', expires: tomorrow });
        setAcceptedCookie(true);
    };

    const cookieBannerStyle = {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        backgroundColor: '#129130',
        padding: '16px',
        textAlign: 'center',
        color: '#fff',
        zIndex: 9999,
        opacity: 0.9
    };

    const buttonStyle = {
        backgroundColor: 'whitesmoke',
        color: 'black',
        border: 'none',
        padding: '8px 16px',
        cursor: 'pointer',
        fontWeight: "bold"
    };
    const isHeader = item => item.mnm_type === 1;
    const itemsPerColumn = Math.ceil(footer_menu.length / 2);
    return (

        <>
            <div className='footer'>
                <Container className='footer-box'>
                    <Row>
                        <Col xs="6" sm="4" md="4" lg="3">
                            <p>
                                <img
                                    src={require('../assets/images/rice_logo.png')}
                                    width="40"
                                    className="d-inline-block align-top"
                                />{' '}
                                <strong className='footer-logo-text'>{'กรมการข้าว'}</strong>
                            </p>
                            <p>
                                <FontAwesomeIcon size="sm" icon={faHouse} className='text-white' />{' '}
                                {address['add_' + cookies.language]}
                            </p>
                            <p>
                                <FontAwesomeIcon size="sm" icon={faEnvelope} className='text-white' />{' '}
                                {address['email']}
                            </p>
                            <p>
                                <FontAwesomeIcon size="sm" icon={faPhone} className='text-white' />{' '}
                                {address['tel']}
                            </p>
                            <p>
                                <FontAwesomeIcon size="sm" icon={faFax} className='text-white' />{' '}
                                {address['fax']}
                            </p>
                            {/* {cookies.siteId === "1" && <p className="ita-box">
                                <a href="/ita" className="ita-box-font">
                                    <img src={require('../assets/images/ITALogo1.png')} width={'40%'} /> <br />
                                    การประเมินคุณธรรมและความโปร่งใส<br />
                                    กรมการข้าว
                                </a>
                            </p>} */}
                            <p>
                                <img src={require('../assets/images/icon_W2_aa.jpg')} width={'30%'} />
                            </p>
                        </Col>
                        <React.Fragment>
                            <Col xs="6" sm="4" md="4" lg="3">
                                <p className='footer-logo-text'>{'แผนผังเว็บไซต์'}</p>
                                {footer_menu.slice(0, itemsPerColumn).map(item => (
                                    <React.Fragment key={item.mnm_id}>
                                        {item.mnm_type === 1 ? (
                                            <p className='footer-header-text'><a href={item.mnm_link}>{item['mnm_name_' + cookies.language]}</a></p>
                                        ) : (
                                            <p className='footer-header-text'>{item['mnm_name_' + cookies.language]}</p>
                                        )}
                                        {item.sub_menu.length > 0 && (
                                            <React.Fragment>
                                                {item.sub_menu.map(subItem => (
                                                    <React.Fragment key={subItem.mns_id}>
                                                        <a href={subItem.mns_link}>&#9654;{" " + subItem['mns_name_' + cookies.language]}</a>
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ))}
                            </Col>

                            <Col xs="6" sm="4" md="4" lg="3">
                                {footer_menu.slice(itemsPerColumn).map(item => (
                                    <React.Fragment key={item.mnm_id}>
                                        {item.mnm_type === 1 ? (
                                            <p className='footer-header-text'><a href={item.mnm_link}>{item['mnm_name_' + cookies.language]}</a></p>
                                        ) : (
                                            <p className='footer-header-text'>{item['mnm_name_' + cookies.language]}</p>
                                        )}
                                        {item.sub_menu.length > 0 && (
                                            <React.Fragment>
                                                {item.sub_menu.map(subItem => (
                                                    <React.Fragment key={subItem.mns_id}>
                                                        <a href={subItem.mns_link}>&#9654;{" " + subItem['mns_name_' + cookies.language]}</a>
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ))}
                            </Col>
                        </React.Fragment>
                        <Col xs="6" sm="4" md="4" lg="3">
                            <p className='footer-header-text'>{lang.follow[cookies.language]}</p>
                            <p>
                                <a href={social_link.facebook}>
                                    <FontAwesomeIcon size="sm" icon={faFacebook} className='text-white' />{' '}
                                    Facebook
                                </a>
                            </p>
                            <p>
                                <a href={social_link.youtube}>
                                    <FontAwesomeIcon size="sm" icon={faYoutube} className='text-white' />{' '}
                                    YouTube
                                </a>
                            </p>
                            <p>
                                <a href={social_link.tiktok}>
                                    <FontAwesomeIcon size="sm" icon={faTiktok} className='text-white' />{' '}
                                    TikTok
                                </a>
                            </p>
                            {
                                !(type === '404' || type === 'ita' || type === 'ita-sub' || type === 'form') && <>
                                    <p className='footer-header-text'>{lang.statistics[cookies.language]}</p>
                                    <p>
                                        <FontAwesomeIcon size="xs" icon={faCalendarDay} className='text-white' />{' ' + lang.statistics.today[cookies.language] + ': '} {statistics['strToday']} <br />
                                        <FontAwesomeIcon size="xs" icon={faCalendarDay} className='text-white' />{' ' + lang.statistics.yesterday[cookies.language] + ': '} {statistics['strYesterday']}  <br />
                                        <FontAwesomeIcon size="xs" icon={faCalendarWeek} className='text-white' />{' ' + lang.statistics.this_month[cookies.language] + ': '} {statistics['strThisMonth']}  <br />
                                        <FontAwesomeIcon size="xs" icon={faCalendarWeek} className='text-white' />{' ' + lang.statistics.last_month[cookies.language] + ': '} {statistics['strLastMonth']}  <br />
                                        <FontAwesomeIcon size="xs" icon={faCalendarDays} className='text-white' />{' ' + lang.statistics.this_year[cookies.language] + ': '} {statistics['strThisYear']}  <br />
                                        <FontAwesomeIcon size="xs" icon={faCalendarDays} className='text-white' />{' ' + lang.statistics.last_year[cookies.language] + ': '} {statistics['strLastYear']}  <br />
                                    </p>
                                </>
                            }
                        </Col>
                    </Row>
                </Container>
            </div >
            {!acceptedCookie && (
                <div style={cookieBannerStyle}>
                    <Container>
                        <b> กรมการข้าว มีการเก็บข้อมูลการใช้งานเว็บไซต์ (Cookies) เพื่อนำเสนอเนื้อหาที่ดีและมีประสิทธิภาพให้กับท่านมากยิ่งขึ้น โดยการเข้าใช้งานเว็บไซต์นี้ถือว่าท่านได้อนุญาตและยอมรับให้มีการใช้คุกกี้ตาม <a href="/page/27011" className="text-cookie">นโยบายการใช้คุกกี้ของกรมการข้าว</a> &nbsp;</b>
                        <Button style={buttonStyle} onClick={handleAcceptCookie}>ยอมรับ</Button>
                    </Container>
                </div>
            )}
            {cookies.siteId === "1" &&
                <div className="floating-icon">
                    <a href="http://m.me/RD.RiceNewsChannel" target="_blank">
                        <img src={require('../assets/images/messenger-icon.png')} alt="Facebook"></img>
                    </a>
                </div>
            }

            <div className='footer-bottom-bar'>
                <Container className='footer-bottom-bar-text'>
                    สงวนลิขสิทธิ์ โดยกรมการข้าว กระทรวงเกษตรและสหกรณ์
                    <p className='footer-bottom-bar-text-mini'>
                        Font by: f0nt | Image by: Pixabay | Pexels | Unsplash | Photoontour | wikipedia Designed by Freepik | Icon made by www.flaticon.com is licensed by CC 3.0 BY | pngtree.com<br /><br />
                        <a href="/page/26990" className="text-cookie">นโยบายเว็บไซต์</a> | <a href="/page/27003" className="text-cookie">นโยบายคุ้มครองข้อมูลส่วนบุคคล</a> | <a href="/page/27010" className="text-cookie">นโยบายรักษาความมั่นคงปลอดภัยเว็บไซต์</a> | <a href="/page/27011" className="text-cookie">นโยบายการใช้คุกกี้</a>
                    </p>
                    <Button onClick={scrollToTop}>กลับสู่ด้านบน</Button>
                </Container >
            </div >
        </>
    )
}

export default Footer