import React, { useState } from "react"
import { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useCookies } from "react-cookie"
import Content1 from "../contents/content-1"
import Content3Swipe from "../contents/content-3-swipe"
import Content4 from "../contents/content-4"
import Content5 from "../contents/content-5"
import Content6 from "../contents/content-6"
import Content7 from "../contents/content-7"
import Content2 from "../contents/content-2"

// แบบไม่มีขอบไม่เว้นระยะ
const Layout6 = ({ content }) => {

    const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])


    return (
        <div>
            <Container className="section-box">
                <h2 className='news-section-header-with-line'>{content['mc_name_' + cookies.language]}</h2>
                {content['pa_type'] === 1 && <Content1 num={8} paId={content['pa_id']} />}
                {content['pa_type'] === 2 && <Content2 num={8} paId={content['pa_id']} />}
                {content['pa_type'] === 3 && <Content3Swipe paId={content['pa_id']} />}
                {content['pa_type'] === 4 && <Content4 paId={content['pa_id']} />}
                {content['pa_type'] === 5 && <Content5 paId={content['pa_id']} />}
                {content['pa_type'] === 6 && <Content6 paId={content['pa_id']} />}
                {content['pa_type'] === 7 && <Content7 num={8} paId={content['pa_id']} />}
            </Container>
        </div>
    )
}

export default Layout6
