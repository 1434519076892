import WebPage from '../_webpage'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { Col, Container, Row } from 'react-bootstrap'
import lang from '../../config/translate.json'
import ita_data from '../../config/ita.json'
import { useCookies } from 'react-cookie'
import { useParams } from 'react-router-dom'


const ItaSubPage = () => {

  let { id } = useParams()

  const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])

  // const ita_data_filter = ita_data.filter(item => item.id.toString() === id)

  return (
    <>
      <WebPage mainComponents={
        <>
          <Header title={ita_data[id - 1]["name_" + cookies.language]} description={ita_data[id - 1]["name_" + cookies.language]} />
          <div>
            <div className={"breadcrumb-box-page-free"}>
              <Container className="breadcrumb-text-box">
                <a href={"/"}>{lang.home[cookies.language]}</a> / <a>{lang.ita[cookies.language]}</a> / <a>{ita_data[id - 1]["name_" + cookies.language]}</a>
              </Container>
            </div>
            <Container className='page-header-box'>
              <Row>
                <Col xs="12">
                  <h2 className='news-section-header-with-line'>{ita_data[id - 1]["name_" + cookies.language]}</h2>
                </Col>
              </Row>
              <Row>

                {
                  ita_data[id - 1]["sub"].map((item, index) => (
                    <Col key={'ita_sub' + index} xs="12" style={{ paddingBottom: '15px', paddingTop: '5px', paddingLeft: "30px" }}>
                      <h4><a href={item.link}>	&#9654; {ita_data[id - 1]["sub"][index]["name_" + cookies.language]}</a></h4>
                    </Col>
                  ))
                }
              </Row>
            </Container>
          </div>
          <Footer type={'ita-sub'} />
        </>
      } />
    </>
  )
}

export default ItaSubPage
