import React, { useState } from "react"
import { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useCookies } from "react-cookie"
import * as httpServices from '../../services/http-services'
import NewsCard from "../../components/atoms/news-card"
import Pagination from "../../components/atoms/pagination"


const Content1 = ({ paId, page = 1, num, isPagination = false }) => {

    const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])

    const [details, setDetails] = useState([])
    const [totalPage, setTotalPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(page)

    const handlePageClick = (newPage) => {
        setCurrentPage(newPage)
    }

    useEffect(() => {
        httpServices.getAllMainContentDetail(cookies.subdomain, 1, paId, currentPage, num).then(response => {
            if (response.data.success) {
                setDetails(response.data.data.content)
                setTotalPage(response.data.data.total_page)
            }
        })
    }, [cookies.subdomain, currentPage])

    return (
        <Row className='news-card-section'>
            {
                details?.map((news) => {
                    return (
                        <Col xs="6" sm="4" md="4" lg="3" key={news['pn_id']}>
                            <a href={"/page/" + news['page_id']}>
                                <NewsCard
                                    key={news['pn_id']}
                                    imgurl={news['pn_mainPic']}
                                    headline={news['pn_title_' + cookies.language]}
                                    shortDescription={news['pn_description_' + cookies.language]}
                                    author={news['site_name_f_TH']}
                                    dateText={news['pn_Date']}
                                />
                            </a>
                        </Col>
                    )
                })
            }
            {details?.length > 0 && <Pagination num={num} totalPage={totalPage} isPagination={isPagination} onClickPage={handlePageClick} label={"ข่าว"} categoryId={paId} />}
        </Row >
    )
}

export default Content1
