import { Col, Row, Container, Form, Button, Drawer } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faA, faAlignJustify, faMinusCircle, faPlusCircle, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import useScrollDirection from '../hooks/useScrollDirection'

import config from '../config/config.json'
import lang from '../config/translate.json'
import { useCookies } from 'react-cookie'
import { useEffect, useRef, useState } from 'react'
import { capitalizeEachFirstLetter } from '../utils'
import * as httpServices from '../services/http-services'
import PRNewsCard from './atoms/pr-news-card'
import { Helmet } from 'react-helmet-async';

const Header = ({ title, description }) => {

    const scrollDirection = useScrollDirection()
    const [menus, setMenus] = useState([])
    const [cookies, setCookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])
    const [keyword, setKeyword] = useState("")
    const [searchResult, setSearchResult] = useState([])
    const [webConfig, setWebConfig] = useState({
        site_id: 0,
        site_name_f_TH: '',
        site_name_f_EN: '',
        site_group: 0
    })
    const [showOffcanvas, setShowOffcanvas] = useState(false)

    const handleToggleOffcanvas = () => {
        setShowOffcanvas((prevShow) => !prevShow);
    }

    useEffect(() => {
        httpServices.getMenuAll(cookies.subdomain).then(response => {
            if (response.data.success) {
                setMenus(response.data.data)
            }
        })

        httpServices.getWebConfig(cookies.subdomain).then(response => {
            if (response.data.success) {
                // document.title = response.data.data.site_name_f_TH
                setCookies('siteId', response.data.data.site_id)
                setWebConfig(response.data.data)
            }
        })
    }, [cookies.language, cookies.subdomain])

    const [currentPagination, setCurrentPagination] = useState(1)
    const [isLoadMore, setIsLoadMore] = useState(false)
    const searchBoxRef = useRef(null)
    const handleScrollSearch = (event) => {
        let currentPosition = event.currentTarget.scrollTop
        let allHeight = searchBoxRef.current.clientHeight - 300
        if (currentPosition === allHeight) {
            if (!isLoadMore) {
                setIsLoadMore(true)
                httpSearch(true, keyword, currentPagination + 1, 20)
                setCurrentPagination(currentPagination + 1)
                // console.log('load more')
            }
        }
    }

    const isMobileScreen = window.innerWidth <= 992

    const handleClickDecreaseTextSize = () => {
        if (cookies.textSize > -3)
            setCookies("textSize", parseInt(cookies.textSize) - 1, { path: '/' })
    }

    const handleClickIncreaseTextSize = () => {
        if (cookies.textSize < 3)
            setCookies("textSize", parseInt(cookies.textSize) + 1, { path: '/' })
    }

    const handleClickResetTextSize = () => {
        setCookies("textSize", 0, { path: '/' })
    }

    const handleSearch = (e) => {
        // let kw = e.target.value
        // check ว่า value เปลี่ยนหรือเปล่า
        // setKeyword(kw)
        setSearchResult([])
        setIsLoadMore(false)
        setCurrentPagination(1)

        if (keyword !== "") {
            httpSearch(false, keyword, 1, 20)
        }
    }

    const handleCloseOffcanvas = () => {
        setShowOffcanvas(false)
    }

    const httpSearch = (isLm, keyword, page, num) => {
        httpServices.searchByKeyword(cookies.subdomain, keyword, page, num).then(response => {
            if (response.data.success) {
                if (!isLm) {
                    setSearchResult(response.data.data)
                } else {
                    let concatSearch = searchResult.concat(response.data.data)
                    setSearchResult(concatSearch)
                }
                setIsLoadMore(false)
            }
        })
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name='description' content={description} />
            </Helmet>
            <Navbar sticky='top' collapseOnSelect expand="lg" className='d-sm-block'>
                <div className={'header-site-group-' + webConfig.site_group}>
                    <div className='header-top-bar'>
                        <Container>
                            <Row>
                                <Col xxl="9" lg="8" md="6" sm="5" xs="3">
                                </Col>
                                <Col xxl="3" lg="4" md="6" sm="7" xs="9" className='header-top-bar-box'>
                                    <Row>
                                        <Col className='header-top-bar-menu'>
                                            <FontAwesomeIcon size="1x" icon={faMinusCircle} onClick={handleClickDecreaseTextSize} /> {''}
                                            <FontAwesomeIcon size="sm" icon={faA} onClick={handleClickResetTextSize} /> {''}
                                            <FontAwesomeIcon size="1x" icon={faPlusCircle} onClick={handleClickIncreaseTextSize} />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <img
                                                src={require('../assets/images/blind_normal.png')}
                                                className="d-inline-block header-top-bar-blind-icon"
                                                onClick={() => setCookies('colorMode', config.COLOR_MODE.NORMAL, { path: '/' })}
                                            />
                                            <img
                                                src={require('../assets/images/blind_bw.png')}
                                                className="d-inline-block header-top-bar-blind-icon"
                                                onClick={() => setCookies('colorMode', config.COLOR_MODE.BW, { path: '/' })}
                                            />
                                            <img
                                                src={require('../assets/images/blind_yellow.png')}
                                                className="d-inline-block header-top-bar-blind-icon"
                                                onClick={() => setCookies('colorMode', config.COLOR_MODE.YELLOW, { path: '/' })}
                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <img
                                                src={require('../assets/images/icon_lang_th.png')}
                                                className="d-inline-block header-top-bar-blind-icon"
                                                alt='ภาษาไทย'
                                                onClick={() => setCookies('language', config.LANGUAGE.TH, { path: '/' })}
                                            />
                                            <img
                                                src={require('../assets/images/icon_lang_en.png')}
                                                className="d-inline-block header-top-bar-blind-icon"
                                                onClick={() => setCookies('language', config.LANGUAGE.EN, { path: '/' })}
                                            />
                                            <img
                                                src={require('../assets/images/icon_lang_cn.png')}
                                                className="d-inline-block header-top-bar-blind-icon"
                                                onClick={() => setCookies('language', config.LANGUAGE.CN, { path: '/' })}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div >
                    <Container className='header-logo-box'>
                        <Row>
                            <Col xl="1" lg="2" md="2" xs="3" className='align-self-center'>
                                <a href={"/"}>
                                    <img
                                        src={require('../assets/images/rice_logo.png')}
                                        className="d-inline-block header-logo-img"
                                        alt='กรมการข้าว'
                                    />
                                </a>
                            </Col>
                            <Col xl="9" lg="8" md="7" xs="8" className='align-self-center'>
                                <div className={'header-title-text'}><a href={"/"}>{webConfig.site_name_f_TH}</a></div>
                                <div className={'header-sub-text'}><a href={"/"}>{capitalizeEachFirstLetter(webConfig.site_name_f_EN)}</a></div>
                            </Col>
                            <Col md="3" xs="1" className='header-hamburger'>
                                <Navbar.Toggle
                                    onClick={handleToggleOffcanvas}
                                    aria-controls="responsive-navbar-nav"
                                    className="ml-auto"
                                />
                            </Col>
                        </Row>
                    </Container>
                </div >
                <div className='header-rice-nav-menu' style={{ '--scroll-direction': scrollDirection === 'down' ? '4' : '0' }}>
                    {!isMobileScreen ? (
                        <Navbar.Collapse id="responsive-navbar-nav" >
                            <Nav className='m-auto'>
                                {
                                    menus?.map((menu) => {
                                        if (menu.sub_menu.length === 0) {
                                            return (
                                                <Nav.Link key={'mnm_' + menu.mnm_id} href={menu.mnm_link}>{capitalizeEachFirstLetter(menu["mnm_name_" + cookies.language])}</Nav.Link>
                                            )
                                        } else {
                                            return (
                                                <NavDropdown key={'mnm_' + menu.mnm_id} title={capitalizeEachFirstLetter(menu["mnm_name_" + cookies.language])} id="collasible-nav-dropdown" renderMenuOnMount={true}>
                                                    {
                                                        menu.sub_menu?.map((sub) => {
                                                            return (
                                                                <NavDropdown.Item key={'mns_' + sub.mns_id} href={sub.mns_link}>{capitalizeEachFirstLetter(sub["mns_name_" + cookies.language])}</NavDropdown.Item>
                                                            )
                                                        })
                                                    }
                                                </NavDropdown>
                                            )
                                        }
                                    })
                                }
                            </Nav>
                        </Navbar.Collapse>
                    ) : (

                        <Navbar.Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="end" className="side-menu">
                            <div className={'sidebar-title-text'}>{webConfig['site_name_f_' + cookies.language]}</div>
                            <div className="close-icon-container">
                                <FontAwesomeIcon icon={faTimes} size="lg" onClick={handleCloseOffcanvas} />
                            </div>
                            <Nav className="me-auto">
                                {menus?.map((menu) => {
                                    if (menu.sub_menu.length === 0) {
                                        return (
                                            <Nav.Link key={'mnm_' + menu.mnm_id} href={menu.mnm_link}>
                                                {capitalizeEachFirstLetter(menu['mnm_name_' + cookies.language])}
                                            </Nav.Link>
                                        )
                                    } else {
                                        return (
                                            <NavDropdown
                                                key={'mnm_' + menu.mnm_id}
                                                title={capitalizeEachFirstLetter(menu['mnm_name_' + cookies.language])}
                                                id="collasible-nav-dropdown"
                                                renderMenuOnMount={true}
                                            >
                                                {menu.sub_menu?.map((sub) => {
                                                    return (
                                                        <NavDropdown.Item key={'mns_' + sub.mns_id} href={sub.mns_link}>
                                                            {capitalizeEachFirstLetter(sub['mns_name_' + cookies.language])}
                                                        </NavDropdown.Item>
                                                    )
                                                })}
                                            </NavDropdown>
                                        )
                                    }
                                })}
                            </Nav>
                        </Navbar.Offcanvas>)}
                </div>
            </Navbar >
            <div className='header-search'>
                <Container className='header-search-box'>
                    <Row>
                        <Col xs="1" md="1" className='header-search-box-icon'>
                            <FontAwesomeIcon size="sm" icon={faSearch} className='text-normal-grey' /> {' '}
                        </Col>
                        <Col>
                            <Form.Control type="text" placeholder={lang.header.search.placeholder[cookies.language]} defaultValue={keyword} onChange={e => {
                                setSearchResult([])
                                setKeyword(e.target.value)
                            }} />
                        </Col>
                        <Col xs="3" md="1">
                            <Button variant="primary" onClick={handleSearch}>
                                {lang.header.search.button[cookies.language]}
                            </Button>
                        </Col>
                    </Row>
                    <Row className='search-result-box' style={{ display: searchResult.length === 0 ? 'none' : 'block' }} onScroll={handleScrollSearch}>
                        <div ref={searchBoxRef}>
                            {
                                searchResult.map((result, index) => {
                                    return (
                                        <PRNewsCard
                                            key={'pd_' + result.pd_id + '_i_' + index}
                                            headline={result['pd_title_' + cookies.language]}
                                            link={"/page/" + result.page_id}
                                            shortDescription={result['pd_description_' + cookies.language]}
                                            author={result['site_name_f_' + cookies.language]}
                                            dateText={result.pd_date}
                                        />
                                    )
                                })
                            }
                        </div>
                    </Row>
                </Container>
            </div >
        </>
    )
}

export default Header