import axios from 'axios'
import config from '../config/config.json'

const QueryString = require('qs')

export const getMenuAll = (subdomain, lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang

    return axios.get(config.BASE_API_URL + '/menu?sub=' + subdomain, {})
}

export const getPageByPId = (subdomain, pid, lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang

    return axios.get(config.BASE_API_URL + '/page?pid=' + pid + '&sub=' + subdomain, {})
}

export const getCategoryByType = (subdomain, type, lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang

    return axios.get(config.BASE_API_URL + '/category?type=' + type + '&sub=' + subdomain, {})
}

export const getMainPage = (subdomain, page, lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang

    return axios.get(config.BASE_API_URL + '/web/main_page?page=' + page + '&sub=' + subdomain, {})
}

export const searchByKeyword = (subdomain, keyword, page, num, lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang

    return axios.get(config.BASE_API_URL + '/web/search?keyword=' + keyword + '&sub=' + subdomain + '&page=' + page + '&num=' + num, {})
}

export const getMainPageSlide = (subdomain, lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang

    return axios.get(config.BASE_API_URL + '/web/slides?sub=' + subdomain, {})
}

export const getWebConfig = (subdomain, lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang

    return axios.get(config.BASE_API_URL + '/web/config?sub=' + subdomain, {})
}

export const getAllMainContent = (subdomain, lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang

    return axios.get(config.BASE_API_URL + '/page/main_content?sub=' + subdomain, {})
}

export const getAllMainContentDetail = (subdomain, pa_type, pa_id, page = 1, num = 0, lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang
    return axios.get(config.BASE_API_URL + '/page/main_content_detail?sub=' + subdomain + '&pa_type=' + pa_type + '&pa_id=' + pa_id + '&page=' + page + '&num=' + num, {})
}

export const getEntersite = (subdomain, lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang
    return axios.get(config.BASE_API_URL + '/web/entersite?sub=' + subdomain, {})
}

export const postForm = async (obj) => {
    //lang === undefined ? lang = config.LANGUAGE.TH : lang = lang
    let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwib3JnX2lkIjowLCJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImFkbWluIiwiZmlyc3RfbmFtZSI6ImFkbWluIiwibGFzdF9uYW1lIjoidGVzdCIsImlhdCI6MTY3OTU5MjQ4MiwiZXhwIjoxNjc5Njc4ODgyfQ.LSk6a5cIa67crEdwtJeEHx1fc4_D1vsG7z6Rb8d-ZSc";
    return axios.post(config.UPLOAD_API_URL + '/api/form', obj, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export const getFormField = async (subdomain, lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang
    return axios.get(config.BASE_API_URL + '/', {});
}

export const getFooterMenu = async (subdomain, lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang
    return axios.get(config.BASE_API_URL + '/menu?sub=' + subdomain, {})
}
export const getSocialNetWork = async (subdomain, lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang
    return axios.get(config.BASE_API_URL + '/web/config?sub=' + subdomain, {})
}

export const getWebAddress = (subdomain, lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang

    return axios.get(config.BASE_API_URL + '/web/address?sub=' + subdomain, {})
}

export const getAllLink = (subdomain, siteGroup, lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang

    return axios.get(config.BASE_API_URL + '/web/all_link?site_group=' + siteGroup + '&sub=' + subdomain, {})
}

export const getPageDetail = (siteId, page, id = 'none', lang = config.LANGUAGE.TH) => {
    lang === undefined ? lang = config.LANGUAGE.TH : lang = lang

    return axios.get(config.BASE_API_URL + '/page/detail?id=' + id + '&page=' + page + '&site_id=' + siteId, {})
}

export const postCounter = async (obj) => {
    //lang === undefined ? lang = config.LANGUAGE.TH : lang = lang
    return axios.post(config.BASE_API_URL + '/page/counter', obj, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
}

export const postComment = async (obj) => {
    const body = QueryString.stringify(obj);
    return axios.post(config.BASE_API_URL + '/page/comment', body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
}