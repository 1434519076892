import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import ImageGallery from 'react-image-gallery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faEye } from '@fortawesome/free-solid-svg-icons'
import { useCookies } from "react-cookie"
import lang from '../../config/translate.json'
import { capitalizeEachFirstLetter } from '../../utils'
import dayjs from '../../../node_modules/dayjs/esm/index';

const DetailContentNews = ({ data, stats }) => {

    const [cookies, setCookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])


    return (
        <div>
            <div className={(data.content && data.content.pa_name_EN.toLowerCase().includes('royal')) ? "breadcrumb-box-news-royal" : "breadcrumb-box-news-pr"}>
                <Container className="breadcrumb-text-box">
                    <a href={"/"}>{lang.home[cookies.language]}</a> / <a>{data.content && data.content['pa_name_' + cookies.language]}</a>
                </Container>
            </div>
            <Container>
                <Row>
                    <Col lg="5" className="news-gallery-box">
                        {data.image_gallery && <ImageGallery items={data.image_gallery} showNav={false} showPlayButton={false} />}
                    </Col>
                    <Col lg="7">
                        <div className="news-page-header">
                            {data.content && capitalizeEachFirstLetter(data.content['pn_title_' + cookies.language])}
                            <hr />
                            <p className='pr-news-thumbnail-attribute'>
                                <FontAwesomeIcon size="xs" icon={faCalendar} className='text-normal-grey' /> <strong>{dayjs(data.content.pn_Date).add(7,'hour').format("DD/MM/YYYY HH:MM")}</strong> &nbsp;&nbsp;
                                <FontAwesomeIcon size="xs" icon={faEye} className='text-normal-grey' /> <strong>{stats['AllVisit']}</strong>
                            </p>
                            <p className="news-page-short-desc">
                                {data.content && data.content['pn_description_' + cookies.language]}
                            </p>
                        </div>
                    </Col>
                </Row>
                <div dangerouslySetInnerHTML={{ __html: data.content && data.content['pn_detail_' + cookies.language] }} className="news-page-content" />
            </Container>
        </div>
    )
}

export default DetailContentNews
