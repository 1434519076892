export function padLeadingZeros(num, size) {
    var s = num + ""
    while (s.length < size) s = "0" + s
    return s
}

export function capitalizeEachFirstLetter(word) {
    if (word !== undefined && word !== null) {
        word.trim()
        const arr = word.split(" ")

        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
        }

        const str2 = arr.join(" ")
        return str2
    } else {
        return ""
    }
}