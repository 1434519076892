import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faPen } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'

const NewsCard = ({ imgurl, headline, shortDescription, author, dateText }) => {

    return (
        <Row className='news-thumbnail'>
            <Col xs="12">
                <img className='news-img-card' src={imgurl} />
            </Col>
            <Col xs="12" className='news-thumbnail-box'>
                <p className='news-thumbnail-headline'>{headline}</p>
                <p className='news-thumbnail-description'>{shortDescription}</p>
                <p className='news-thumbnail-attribute'>
                    <FontAwesomeIcon size="xs" icon={faPen} className='text-normal-grey' /> <strong>{author}</strong> <br />
                    <FontAwesomeIcon size="xs" icon={faCalendar} className='text-normal-grey' /> <strong>{dayjs(dateText).add(7,'hour').format("DD/MM/YYYY HH:MM")}</strong>
                </p>
            </Col>
        </Row>
    )
}

export default NewsCard