import React, { useState } from "react"
import { useEffect } from 'react'
import { Row, Col, Carousel } from 'react-bootstrap'
import { useCookies } from "react-cookie"
import * as httpServices from '../../services/http-services'

// Infographic
const Content5 = ({ paId }) => {

    const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])

    const [details, setDetails] = useState([])

    useEffect(() => {
        httpServices.getAllMainContentDetail(cookies.subdomain, 5, paId).then(response => {
            if (response.data.success) {
                setDetails(response.data.data.content)
            }
        })
    }, [cookies.subdomain])

    return (
        <Row className='news-card-section'>
            <Col xs="1" md="3"></Col>
            <Col xs="10" md="6">
                <Carousel>
                    {
                        details?.map((item) => {
                            return (
                                <Carousel.Item key={'pi_' + item.pi_id} interval={4000} className="infographic">
                                    <a href={item.pi_link === "" ? "#" : item.pi_link}>
                                        <img
                                            className="d-block infographic-img"
                                            src={item.pi_pic}
                                        />
                                    </a>
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
            </Col>
            <Col xs="1" md="3"></Col>
        </Row >
    )
}

export default Content5
