import WebPage from './_webpage'
import Header from '../components/header'
import Footer from '../components/footer'
import { Container } from 'react-bootstrap'


const Page404 = () => {

  return (
    <>
      <WebPage mainComponents={
        <>
          <Header />
          <Container>
            <div className='page404-box'>
              <h2>ขออภัยในความไม่สะดวก</h2>
              <h1>รหัส 404</h1>
              <h1>ไม่พบหน้าที่ท่านต้องการเข้าชม</h1>
            </div>
          </Container>
          <Footer type={'404'}/>
        </>
      } />
    </>
  )
}

export default Page404
