import React, { useState, useEffect } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { useCookies } from "react-cookie";
import * as httpServices from '../../services/http-services';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

const Content3 = ({ paId }) => {
  const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])
  const [details, setDetails] = useState([]);

  useEffect(() => {
    httpServices.getAllMainContentDetail(cookies.subdomain, 3, paId).then(response => {
      if (response.data.success) {
        setDetails(response.data.data.content);
      }
    });
  }, [paId, cookies.subdomain]);

  return (
    <Container>
      <Row>
        {
          details?.map((list) => {
            return (
              <Col md="3" key={'pb_' + list.pb_id} className="category-services-box">
                <a href={list.pb_link}>
                  <img src={list.pb_pic} width={'100%'} />
                  {list['pb_title_' + cookies.language]}
                </a>
              </Col>
            )
          })
        }
      </Row>
    </Container>
  )
}

export default Content3;
