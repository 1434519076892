import WebPage from './_webpage'
import Header from '../components/header'
import Footer from '../components/footer'
import { useCookies } from 'react-cookie'
import * as httpServices from '../services/http-services'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DetailContentFree from './details/detail-content-free'
import DetailContentNews from './details/detail-content-news'
import DetailContentDoc from './details/detail-content-doc'


const Page = () => {

  let { pid, page } = useParams()
  const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])
  const [pageData, setPageData] = useState({})
  const [pageStats, setPageStats] = useState({
    "strToday": "0",
    "strYesterday": "0",
    "strThisMonth": "0",
    "strLastMonth": "0",
    "strThisYear": "0",
    "strLastYear": "0",
    "AllVisit": "0"
  })

  const statisticsCallback = (stats) => {
    setPageStats(stats)
  }

  useEffect(() => {
    if (pid !== undefined) {
      httpServices.getPageByPId(cookies.subdomain, pid).then(response => {
        if (response.data.success) {
          // console.log(response.data.data)
          setPageData(response.data.data)
        }
      })
    } else if (page !== undefined) {
      if (!page.includes('.php')) {
        httpServices.getMainPage(cookies.subdomain, page).then(response => {
          if (response.data.success) {
            // console.log(response.data.data)
            setPageData(response.data.data)
          }
        })
      } else {
        window.location.replace('/')
      }
    } else {
      console.log('another page')
    }
  }, [cookies.language, cookies.subdomain])

  if (pid !== undefined) {
    // กรณี page
    return (
      <>
        <WebPage mainComponents={
          <>
            <Header title={pageData.detail !== undefined ? pageData.detail['title_' + cookies.language] : ''} description={pageData.detail !== undefined ? pageData.detail['title_' + cookies.language] : ''} />
            {pageData.detail && pageData.detail.t_id === 2 && <DetailContentNews stats={pageStats} data={pageData} />}
            {pageData.detail && pageData.detail.t_id === 3 && <DetailContentDoc stats={pageStats} data={pageData} />}
            {pageData.detail && pageData.detail.t_id === 4 && <DetailContentFree stats={pageStats} data={pageData} content={pageData.content['po_detail_' + cookies.language]} />}
            {pageData.detail && <Footer statisticsCallback={statisticsCallback} type={'page'} id={pageData.detail.page_id} />}
          </>
        } />
      </>
    )
  } else if (page !== undefined) {
    // กรณีเพจบังคับอื่น ๆ
    let prefix = "ph";
    let description = ""
    if (page === "history") {
      prefix = "ph"
    } else if (page === "vision") {
      prefix = "pv"
    } else if (page === "organization") {
      prefix = "pog"
    }

    return (
      <>
        <WebPage mainComponents={
          <>
            <Header title={pageData.detail !== undefined ? pageData.detail['title_' + cookies.language] : ''} description={pageData.detail !== undefined ? pageData.detail['title_' + cookies.language] : ''} />
            {pageData.detail && <DetailContentFree data={pageData} content={pageData.detail[prefix + '_detail_' + cookies.language]} />}
            <Footer statisticsCallback={statisticsCallback} type={page} />
          </>
        } />
      </>
    )
  }
}

export default Page
