import WebPage from './_webpage'
import Header from '../components/header'
import Footer from '../components/footer'
import { useCookies } from 'react-cookie'
import * as httpServices from '../services/http-services'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import lang from '../config/translate.json'
import { capitalizeEachFirstLetter } from '../utils'
import Content1 from './contents/content-1'
import Content2 from './contents/content-2'
import Content3 from './contents/content-3'
import Content4 from './contents/content-4'
import Content5 from './contents/content-5'
import Content6 from './contents/content-6'
import Content7 from './contents/content-7'


const Category = () => {

  let { type } = useParams()
  const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])
  const [pageData, setPageData] = useState({})

  useEffect(() => {
    // console.log(type, page)
    if (type !== undefined) {
      httpServices.getCategoryByType(cookies.subdomain, type).then(response => {
        if (response.data.success) {
          if (response.data.data.length !== 0) {
            // console.log(response.data.data)
            setPageData(response.data.data)
          } else {
            // navigate('/404')
          }
        }
      })
    } else {
      // navigate('/404')
    }
  }, [cookies.language, cookies.subdomain])

  return (
    <>
      <WebPage mainComponents={
        <>
          <Header title={pageData.detail !== undefined ? pageData.detail['pa_name_' + cookies.language] : ''} description={pageData.detail !== undefined ? pageData.detail['pa_name_' + cookies.language] : ''} />
          <div>
            <div className={"breadcrumb-box-page-free"}>
              <Container className="breadcrumb-text-box">
                <a href={"/"}>{lang.home[cookies.language]}</a> / <a>{pageData.detail && pageData.detail['pa_name_' + cookies.language]}</a>
              </Container>
            </div>
            <Container className='page-header-box'>
              <Row className='news-section-header-box'>
                <Col>
                  <div>
                    <h2 className='news-section-header-with-line'>{pageData.detail && capitalizeEachFirstLetter(pageData.detail['pa_name_' + cookies.language])}</h2>
                  </div>
                </Col>
              </Row>
              <Row>
                {pageData.detail && pageData.detail.pa_type === 1 && <Content1 num={12} isPagination={true} paId={pageData.detail.pa_id} />}
                {pageData.detail && pageData.detail.pa_type === 2 && <Content2 num={10} isPagination={true} paId={pageData.detail.pa_id} />}
                {pageData.detail && pageData.detail.pa_type === 3 && <Content3 paId={pageData.detail.pa_id} />}
                {pageData.detail && pageData.detail.pa_type === 4 && <Content4 paId={pageData.detail.pa_id} />}
                {pageData.detail && pageData.detail.pa_type === 5 && <Content5 paId={pageData.detail.pa_id} />}
                {pageData.detail && pageData.detail.pa_type === 6 && <Content6 paId={pageData.detail.pa_id} />}
                {pageData.detail && pageData.detail.pa_type === 7 && <Content7 num={10} isPagination={true} paId={pageData.detail.pa_id} />}
              </Row>
            </Container>
          </div>
          {pageData.detail && <Footer type={'category'} id={pageData.detail.pa_id} />}
        </>
      } />
    </>
  )
}

export default Category
