import WebPage from '../_webpage'
import Header from '../../components/header'
import Footer from '../../components/footer'

import lang from '../../config/translate.json'
import * as httpServices from '../../services/http-services'

import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Col, Container, Row } from 'react-bootstrap'
import PersonCard from '../../components/atoms/person-card'


const PersonnelPage = () => {

  const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])
  const [personnelData, setPersonnelData] = useState({})

  useEffect(() => {
    httpServices.getMainPage(cookies.subdomain, 'personnel').then(response => {
      if (response.data.success) {
        setPersonnelData(response.data.data)
      }
    })
  }, [cookies.language, cookies.subdomain])

  return (
    <>
      <WebPage mainComponents={
        <>
          <Header title={lang.personnel[cookies.language]} description={lang.personnel[cookies.language]} />
          <div className={"breadcrumb-box-page-free"}>
            <Container className="breadcrumb-text-box">
              <a href={"/"}>{lang.home[cookies.language]}</a> / <a>{lang.personnel[cookies.language]}</a>
            </Container>
          </div>
          <Container className='page-header-box'>
            <Row className='news-section-header-box'>
              <Col>
                <div>
                  <h2 className='news-section-header-with-line'>{lang.personnel[cookies.language]}</h2>
                </div>
              </Col>
            </Row>
            <Row className='news-section-header-box'>
              {
                personnelData.psn_sections && personnelData.psn_sections.map((section, sIndex) => {
                  if (section['psn_child']) {
                    return (
                      <div key={'psn' + sIndex} className='personnel-section'>
                        <h4>{section['stc_name']}</h4>
                        <Row>
                          {
                            section['psn_child'].map((child, cIndex) => {
                              return (
                                <Row key={'psnc' + cIndex}>
                                  {
                                    child.map((person, pIndex) => {
                                      return (
                                        <Col key={'psncper' + pIndex} xs="10" sm="6" md="3" className='mx-auto'>
                                          <PersonCard data={person} />
                                        </Col>
                                      )
                                    })
                                  }
                                </Row>
                              )
                            })
                          }
                        </Row>
                      </div>
                    )
                  }
                })
              }
            </Row>
          </Container>
          <Footer type={'personnel'} />
        </>
      } />
    </>
  )
}

export default PersonnelPage
