import WebPage from '../_webpage'
import Header from '../../components/header'
import Footer from '../../components/footer'

import lang from '../../config/translate.json'
import * as httpServices from '../../services/http-services'

import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'


const AllLinkPage = () => {

  const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])
  const [allLinkSiteGroup1, setAllLinkSiteGroup1] = useState([])
  const [allLinkSiteGroup2, setAllLinkSiteGroup2] = useState([])
  const [allLinkSiteGroup3, setAllLinkSiteGroup3] = useState([])

  useEffect(() => {
    httpServices.getAllLink(cookies.subdomain, 1).then(response => {
      if (response.data.success) {
        setAllLinkSiteGroup1(response.data.data)
      }
    })
    httpServices.getAllLink(cookies.subdomain, 3).then(response => {
      if (response.data.success) {
        setAllLinkSiteGroup2(response.data.data)
      }
    })
    httpServices.getAllLink(cookies.subdomain, 2).then(response => {
      if (response.data.success) {
        setAllLinkSiteGroup3(response.data.data)
      }
    })
  }, [cookies.language, cookies.subdomain])

  return (
    <>
      <WebPage mainComponents={
        <>
          <Header title={'หน่วยงานภายในกรมการข้าว'} description={'หน่วยงานภายในกรมการข้าว'} />
          <div className={"breadcrumb-box-page-free"}>
            <Container className="breadcrumb-text-box">
              <a href={"/"}>{lang.home[cookies.language]}</a> / <a>{lang.internal_department[cookies.language]}</a>
            </Container>
          </div>
          <Container className='page-header-box'>
            <Row className='news-section-header-box'>
              <Col>
                <div>
                  <h2 className='news-section-header-with-line'>{lang.internal_department[cookies.language]}</h2>
                </div>
              </Col>
            </Row>
            <Row className='news-section-header-box'>
              <Tabs
                id="justify-tab-example"
                className="mb-3"
                justify
              >
                <Tab eventKey={1} title={lang.internal_department.central[cookies.language] ?? ""}>
                  <Row>
                    {
                      allLinkSiteGroup1.map((site, index) => {
                        return (
                          <Col key={"al1" + index} xs="12" sm="6" md="4" lg="3">
                            <div className='all-link'>
                              <div className='all-link-box-1'>
                                <a href={site['site_name_s_EN'] === 'RD' ? 'https://www.ricethailand.go.th' : 'https://' + site['site_name_s_EN'] + '.ricethailand.go.th'}>
                                  {site['site_name_f_' + cookies.language]}
                                </a>
                              </div>
                            </div>
                          </Col>
                        )
                      })
                    }
                  </Row>
                </Tab>
                <Tab eventKey={2} title={lang.internal_department.seed_center[cookies.language] ?? ""}>
                  <Row>
                    {
                      allLinkSiteGroup2.map((site, index) => {
                        return (
                          <Col key={"al2" + index} xs="12" sm="6" md="4" lg="3">
                            <div className='all-link'>
                              <div className='all-link-box-3'>
                                <a href={site['site_name_s_EN'] === 'RD' ? 'https://www.ricethailand.go.th' : 'https://' + site['site_name_s_EN'] + '.ricethailand.go.th'}>
                                  {site['site_name_f_' + cookies.language]}
                                </a>
                              </div>
                            </div>
                          </Col>
                        )
                      })
                    }
                  </Row>
                </Tab>
                <Tab eventKey={3} title={lang.internal_department.research_center[cookies.language] ?? ""}>
                  <Row>
                    {
                      allLinkSiteGroup3.map((site, index) => {
                        return (
                          <Col key={"al3" + index} xs="12" sm="6" md="4" lg="3">
                            <div className='all-link'>
                              <div className='all-link-box-2'>
                                <a href={site['site_name_s_EN'] === 'RD' ? 'https://www.ricethailand.go.th' : 'https://' + site['site_name_s_EN'] + '.ricethailand.go.th'}>
                                  {site['site_name_f_' + cookies.language]}
                                </a>
                              </div>
                            </div>
                          </Col>
                        )
                      })
                    }
                  </Row>
                </Tab>
              </Tabs>
            </Row>
          </Container>
          <Footer type={'all_link'} />
        </>
      } />
    </>
  )
}

export default AllLinkPage
