import React, { useState } from "react"
import { useEffect } from 'react'
import { useCookies } from "react-cookie"
import * as httpServices from '../../services/http-services'


const Content6 = ({ paId }) => {

    const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])

    const [details, setDetails] = useState([])

    useEffect(() => {
        httpServices.getAllMainContentDetail(cookies.subdomain, 6, paId).then(response => {
            if (response.data.success) {
                setDetails(response.data.data.content)
            }
        })
    }, [cookies.subdomain])

    return (
        <div className="section-box">
            {details?.map((detail, index) => {
                return (
                    <div key={'ct6' + index + detail['pa_id']} >
                        <div dangerouslySetInnerHTML={{ __html: detail['po_detail_' + cookies.language] }} />
                    </div>
                )
            })}
        </div>
    )
}

export default Content6
