import React, { useState } from "react"
import { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useCookies } from "react-cookie"
import * as httpServices from '../../services/http-services'
import PRNewsCard from "../../components/atoms/pr-news-card"
import Pagination from "../../components/atoms/pagination"


const Content2 = ({ paId, page = 1, num, isPagination = false }) => {

    const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])

    const [details, setDetails] = useState([])
    const [totalPage, setTotalPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(page)

    const handlePageClick = (newPage) => {
        setCurrentPage(newPage)
    }

    useEffect(() => {
        httpServices.getAllMainContentDetail(cookies.subdomain, 2, paId, currentPage, num).then(response => {
            if (response.data.success) {
                setDetails(response.data.data.content)
                setTotalPage(response.data.data.total_page)
            }
        })
    }, [cookies.subdomain, currentPage])

    return (
        <Row className='news-card-section'>
            {
                details?.map((news) => {
                    return (
                        <Col xs="12" key={news['pd_id']}>
                            <PRNewsCard
                                headline={news['pd_title_' + cookies.language]}
                                shortDescription={news['pd_description_' + cookies.language]}
                                author={news['site_name_f_' + cookies.language]}
                                dateText={news['pd_date']}
                                link={"/page/" + news['page_id']}
                            />
                        </Col>
                    )
                })
            }
            {details?.length > 0 && <Pagination num={num} totalPage={totalPage} isPagination={isPagination} onClickPage={handlePageClick} label={"เอกสาร"} categoryId={paId} />}
        </Row >
    )
}

export default Content2
