import WebPage from '../_webpage'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faFax, faHouse, faPhone } from '@fortawesome/free-solid-svg-icons'

import { useCookies } from "react-cookie"
import lang from '../../config/translate.json'
import { useEffect, useState } from 'react'
import * as httpServices from '../../services/http-services'

const AddressPage = () => {

  const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])

  const [pageData, setPageData] = useState(null)


  useEffect(() => {
    httpServices.getMainPage(cookies.subdomain, 'address').then(response => {
      if (response.data.success) {
        setPageData(response.data.data)
      }
    })

  }, [cookies.language, cookies.subdomain])


  return (
    <>
      <WebPage mainComponents={
        <>
          <Header title={'การติดต่อ'} description={'การติดต่อ'} />
          <div>
            <div className={"breadcrumb-box-page-free"}>
              <Container className="breadcrumb-text-box">
                <a href={"/"}>{lang.home[cookies.language]}</a> / <a>{lang.contact[cookies.language]}</a>
              </Container>
            </div>
            <Container className='page-header-box'>
              <Row>
                <Col xs="12">
                  <h2 className='news-section-header-with-line'>{lang.contact[cookies.language]}</h2>
                </Col>
                <Col xs="12">
                  {pageData && <iframe style={{ width: '100%', height: '25rem' }} frameBorder="0" src={"https://maps.google.com/maps?q=" + pageData.detail.add_lat + "," + pageData.detail.add_long + "&t=&z=17&ie=UTF8&iwloc=&output=embed"} ></iframe>}
                </Col>
              </Row>
              <Row style={{ marginTop: '1rem' }}>
                <Col md="3">
                  <FontAwesomeIcon size="sm" icon={faHouse} />{' '}
                  {pageData && pageData.detail['add_' + cookies.language]}
                </Col>
                <Col md="3">
                  <FontAwesomeIcon size="sm" icon={faPhone} />{' '}
                  {pageData && pageData.detail['tel']}
                </Col>
                <Col md="3">
                  <FontAwesomeIcon size="sm" icon={faFax} />{' '}
                  {pageData && pageData.detail['fax']}
                </Col>
                <Col md="3">
                  <FontAwesomeIcon size="sm" icon={faEnvelope} />{' '}
                  {pageData && pageData.detail['email']}
                </Col>
              </Row>
            </Container>
          </div>
          <Footer type={'address'} />
        </>
      } />
    </>
  )
}

export default AddressPage
