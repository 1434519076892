import React, { } from "react"
import { Container, Row, Tabs, Tab } from 'react-bootstrap'
import { useCookies } from "react-cookie"
import Content1 from "../contents/content-1"
import Content2 from "../contents/content-2"

// ไม่มีชื่อหัวข้อแบบชนิดมีหัวข้อย่อย
const Layout7 = ({ content }) => {

    const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])


    return (
        <div>
            <Container className="section-box">
                <Tabs
                    // defaultActiveKey={content['sub_mc'][0]['mc_id']}
                    id="justify-tab-example"
                    className="mb-3"
                    justify
                >
                    {
                        content['sub_mc']?.map((tab, index) => {
                            return (
                                <Tab key={tab['mc_id']} eventKey={tab['mc_id']} title={tab['mc_name_' + cookies.language] ?? ""}>
                                    <Row className='news-card-section'>
                                        {tab['pa_type'] === 1 && <Content1 num={8} paId={tab['pa_id']} />}
                                        {tab['pa_type'] === 2 && <Content2 num={8} paId={tab['pa_id']} />}
                                    </Row>
                                </Tab>
                            )
                        })
                    }
                </Tabs>
            </Container>
        </div >
    )
}

export default Layout7
