import React from "react"
import { useEffect } from 'react'

import '../scss/App.scss'
import { useCookies } from "react-cookie"
import config from '../config/config.json'



const WebPage = ({ mainComponents }) => {

  const [cookies, setCookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])

  useEffect(() => {
    if (cookies.textSize === undefined)
      setCookies("textSize", 0, { path: '/' })

    if (cookies.colorMode === undefined)
      setCookies("colorMode", config.COLOR_MODE.NORMAL, { path: '/' })

    if (cookies.language === undefined)
      setCookies("language", config.LANGUAGE.TH, { path: '/' })
  }, [])

  if (cookies.language === undefined) {
    return (<></>)
  }

  return (
    <div style={{ '--font-size-val': cookies.textSize, '--color-mode-val': cookies.colorMode }} className={'main-content color-mode-' + cookies.colorMode}>
      {mainComponents}
    </div>
  )
}

export default WebPage
