import React, { useState } from "react"
import { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useCookies } from "react-cookie"
import * as httpServices from '../../services/http-services'


const Content4 = ({ paId }) => {

    const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])

    const [details, setDetails] = useState([])

    useEffect(() => {
        httpServices.getAllMainContentDetail(cookies.subdomain, 4, paId).then(response => {
            if (response.data.success) {
                setDetails(response.data.data.content)
            }
        })
    }, [cookies.subdomain])

    return (
        <Row className='news-card-section'>
            {
                details?.map((item) => {
                    return (
                        <Col xs="4" key={'pc_' + item['pc_id']}>
                            <Row className="banner-circle-box">
                                <Col lg="3">
                                    <a href={item['pc_link']} target="_blank"><img className="banner-circle-img" src={item['pc_pic']} /></a>
                                </Col>
                                <Col lg="9" className="banner-circle-text">
                                    <a href={item['pc_link']} target="_blank">{item['pc_title_' + cookies.language]}</a>
                                </Col>
                            </Row>
                        </Col>
                    )
                })
            }
        </Row >
    )
}

export default Content4
