import { Carousel } from 'react-bootstrap'

const RiceCarousel = ({ datas }) => {

    return (
        <>
            <Carousel>
                {
                    datas?.map(carousel => {
                        return (
                            <Carousel.Item key={'ss_' + carousel.ss_id} interval={4000}>
                                <a href={carousel.link === "" ? "#" : carousel.link}>
                                    <img
                                        className="d-block w-100"
                                        src={carousel.ss_url_img}
                                        alt={'TODO'}
                                    />
                                </a>
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
        </>
    )
}

export default RiceCarousel