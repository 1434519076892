import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import { useCookies } from "react-cookie"
import lang from '../../config/translate.json'
import { capitalizeEachFirstLetter } from '../../utils'

const DetailContentFree = ({ data, content, stats }) => {

    const [cookies, setCookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])


    return (
        <div>
            <div className={"breadcrumb-box-page-free"}>
                <Container className="breadcrumb-text-box">
                    <a href={"/"}>{lang.home[cookies.language]}</a> / <a>{data.content && data.content['po_title_' + cookies.language]}</a>
                </Container>
            </div>
            <Container className='page-header-box'>
                <Row className='news-section-header-box'>
                    <Col>
                        <div>
                            <h2 className='news-section-header-with-line'>{data.detail && capitalizeEachFirstLetter(data.detail['title_' + cookies.language])}</h2>
                        </div>
                    </Col>
                </Row>
                <div dangerouslySetInnerHTML={{ __html: content && content }} className="page-content" />
            </Container>
        </div>
    )
}

export default DetailContentFree
