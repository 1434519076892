import WebPage from '../_webpage'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { Col, Container, Row } from 'react-bootstrap'
import lang from '../../config/translate.json'
import { useCookies } from 'react-cookie'
import ita_data from '../../config/ita.json'


const ItaPage = () => {

  const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])

  return (
    <>
      <WebPage mainComponents={
        <>
          <Header title={lang.ita[cookies.language]} description={lang.ita[cookies.language]} />
          <div>
            <div className={"breadcrumb-box-page-free"}>
              <Container className="breadcrumb-text-box">
                <a href={"/"}>{lang.home[cookies.language]}</a> / <a>{lang.ita[cookies.language]}</a>
              </Container>
            </div>
            <Container className='page-header-box'>
              <Row>
                <Col xs="12">
                  <h2 className='news-section-header-with-line'>{lang.ita[cookies.language]}</h2>
                </Col>
              </Row>
              <Row>
                {
                  ita_data.map((item, index) => (
                    <Col key={'ita_m' + index} xs="6" style={{ paddingTop: '20px' }}>
                      <h4>
                        <a href={`/ita-sub/${item.id}`}>	&#9654; {ita_data[index]["name_" + cookies.language]}</a>
                      </h4>
                    </Col>
                  ))
                }
              </Row>
            </Container>
          </div>
          <Footer type={'ita'} />
        </>
      } />
    </>
  )
}

export default ItaPage
