import { Col, Row } from 'react-bootstrap'
import lang from '../../config/translate.json'
import { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useCookies } from 'react-cookie'

const Pagination = ({ isPagination, label, num, totalPage, onClickPage, categoryId = "" }) => {

    const [pageButtonNum, setPageButtonNum] = useState(0)
    const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])

    const handlePageClick = (event) => {
        let selectedPage = event.selected + 1
        onClickPage(selectedPage)
    }

    useEffect(() => {
        if (isPagination) {
            let pageBtNum = parseInt(totalPage / num) + 1
            setPageButtonNum(pageBtNum)
        }
    }, [totalPage])

    return (
        <Row className='paginationBox'>
            {!isPagination && <Col>
                <a href={"/category/" + categoryId}>อ่าน{label}ทั้งหมด {">"}</a>
            </Col>}
            {isPagination && <Col>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={"> " + lang.next[cookies.language]}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageButtonNum}
                    previousLabel={"< " + lang.previous[cookies.language]}
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </Col>}
        </Row>
    )
}

export default Pagination