import React, { useState, useEffect, useCallback } from 'react'
import { Accordion, Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import lang from '../../config/translate.json'
import WebPage from '../_webpage'
import Header from '../../components/header'
import Footer from '../../components/footer'
import * as httpServices from '../../services/http-services'
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3'


const FormPage = () => {
  const [cookies, setCookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])

  const [pageData, setPageData] = useState({})
  const [token, setToken] = useState()
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
  const [form_data, setFormData] = useState(
    {
      "site_id": 1,
      "type": "comment",
      "forms": [
        {
          "name": "name",
          "title": "ชื่อนามสกุล",
          "as": "input"
        },
        {
          "name": "email",
          "title": "อีเมล",
          "as": "input"
        },
        {
          "name": "tel",
          "title": "เบอร์โทรศัพท์ติดต่อกลับ",
          "as": "input"
        },
        {
          "name": "topic",
          "title": "ชื่อเรื่องประเด็นที่สอบถาม",
          "as": "input"
        },
        {
          "name": "detail",
          "title": "รายละเอียดที่สอบถาม",
          "as": "textarea"
        }
      ]
    }
  )

  const handleVerifyRecaptcha = useCallback((token) => {
    setToken(token)
  }, [])

  const postQuestion = (e) => {
    e.preventDefault()
    let formData = {}
    let notValidated = false
    form_data.forms.forEach((formField) => {
      const fieldValue = document.getElementById(formField.name).value
      formData[formField.name] = fieldValue
      if (fieldValue === '' || fieldValue === undefined) {
        notValidated = true
      }
    })
    formData['site_id'] = cookies.siteId
    formData['type'] = 'comment'
    if (!notValidated) {
      httpServices.postComment(formData).then((response) => {
        console.log(response.data)
        if (response.data.success) {
          alert('ส่งความคิดเห็นสำเร็จ')
          window.location.reload(false)
        }
      })
      setRefreshReCaptcha((r) => !r)
    } else {
      alert('กรุณากรอกให้ครบทุกข่อง')
    }
  }

  // const loadForm = () => {
  //   httpServices.getFormField()
  //     .then((response) => {
  //       let data = response.data
  //       let lstForm = []
  //       lstForm.map((item) => {
  //         lstForm.push({
  //           "site_id": item.site_id,
  //           "type": item.type,
  //           "forms": item.forms
  //         })
  //       })
  //       setFormData(lstForm)
  //     })
  // }

  useEffect(() => {
    //loadForm()
    // console.log(form_data)
  }, [cookies.language])

  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey="6Le7jzYnAAAAAJQ3ah0KeWPDbwa2gAoeulGf8-Yw">
        <WebPage

          mainComponents={
            <>
              <Header title={lang.qanda[cookies.language]} description={lang.qanda[cookies.language]} />
              <div>
                <div className={'breadcrumb-box-page-free'}>
                  <Container className="breadcrumb-text-box">
                    <a href={"/"}>{lang.home[cookies.language]}</a> / <a>{lang.qanda[cookies.language]}</a>
                  </Container>
                </div>
                <Container className="page-header-box">
                  <Row>
                    <Col xs="12">
                      <h2 className="news-section-header-with-line">{lang.qanda[cookies.language]}</h2>
                    </Col>
                    <Col xs="12" style={{ padding: '3rem' }}>
                      <Form>
                        {
                          form_data &&
                          form_data.forms.map((formField) => (
                            <Form.Group className='mb-3' key={formField.name}>
                              <Form.Control
                                id={formField.name}
                                placeholder={`กรุณากรอก ${formField.title}`}
                                as={formField.as}
                                required
                              />
                            </Form.Group>
                          ))
                        }
                        <GoogleReCaptcha action="homepage" onVerify={handleVerifyRecaptcha} />
                        <Button variant="primary" type="submit" onClick={postQuestion} disabled={!token}>
                          ส่ง
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <h2 className="news-section-header-with-line">{lang.faq[cookies.language]}</h2>
                    </Col>
                    <Col xs="12" style={{ padding: '3rem' }}>
                      <Container>
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>จะติดต่อกรมการข้าวได้อย่างไร?</Accordion.Header>
                            <Accordion.Body>
                              ที่อยู่หน่วยงาน : กรมการข้าว เลขที่ 2177 ถนนพหลโยธิน แขวงลาดยาว เขตจตุจักร กรุงเทพฯ 10900<br />
                              E-mail : ict_rd@rice.mail.go.th<br />
                              Tel : 02-5612370<br />
                              Fax : 0-2561-3236<br />
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>บทบาทหน้าที่ของกรมการข้าว? </Accordion.Header>
                            <Accordion.Body>
                              กรมการข้าว มีภารกิจเกี่ยวกับข้าว โดยครอบคลุมถึงการปรับปรุงพัฒนาการปลูกข้าว<br />
                              ให้มีผลผลิตต่อพื้นที่และคุณภาพสูงขึ้น การพัฒนาพันธุ์ การอนุรักษ์ และคุ้มครองพันธุ์ การผลิตเมล็ดพันธุ์<br />
                              การตรวจสอบและรับรองมาตรฐาน การส่งเสริม สนับสนุน และเผยแพร่องค์ความรู้เพื่อพัฒนาคุณภาพ<br />
                              ชีวิตชาวนา การแปรรูป และการจัดการอื่น ๆ เพื่อเพิ่มมูลค่าข้าว รวมทั้งการตลาดและการส่งเสริมวัฒนธรรม<br />
                              และภูมิปัญญาท้องถิ่นเกี่ยวกับข้าว โดยให้มีอำนาจหน้าที่ดังต่อไปนี้<br />
                              (๑) ศึกษา วิเคราะห์ เสนอแนะ และจัดทำนโยบายและยุทธศาสตร์ข้าวของประเทศ รวมทั้ง<br />
                              ดำเนินการเกี่ยวกับความร่วมมือกับต่างประเทศในเรื่องข้าว<br />
                              (๒) ศึกษา วิจัย ทดลอง และพัฒนาเกี่ยวกับพันธุ์ เทคโนโลยีการผลิต การอารักขา วิทยาการ<br />
                              หลังการเก็บเกี่ยวและการแปรรูป และมาตรฐานพันธุ์ข้าว<br />
                              (๓) ดำเนินการเกี่ยวกับการอนุรักษ์พันธุกรรมและคุ้มครองพันธุ์ข้าว<br />
                              (๔) ดำเนินการเกี่ยวกับการตรวจสอบและรับรองมาตรฐานข้าว<br />
                              (๕) ดำเนินการผลิตและส่งเสริมสนับสนุนเกี่ยวกับเมล็ดพันธุ์ข้าว<br />
                              (๖) ส่งเสริมและสนับสนุนการสร้างมูลค่าเพิ่ม การพัฒนาระบบการจัดการสินค้าข้าว การแปรรูปข้าว<br />
                              และผลิตภัณฑ์ และสนับสนุนการค้าข้าวและผลิตภัณฑ์ข้าวเพื่อตลาดเฉพาะ<br />
                              (๗) ส่งเสริม สนับสนุน และถ่ายทอดเทคโนโลยีการผลิตและการจัดการผลผลิตข้าว รวมทั้ง<br />
                              อนุรักษ์และส่งเสริมวัฒนธรรมและภูมิปัญญาท้องถิ่นเกี่ยวกับข้าว<br />
                              (๘) ส่งเสริม สนับสนุน และเผยแพร่องค์ความรู้แก่ชาวนาและผู้ประกอบการด้านข้าว<br />
                              (๙) ปฏิบัติการอื่นใดตามที่กฎหมายกำหนดให้เป็นอำนาจหน้าที่ของกรมหรือตามที่รัฐมนตรี<br />
                              หรือคณะรัฐมนตรีมอบหมาย<br /><br />

                              ที่มา : กฎกระทรวงแบ่งส่วนราชการกรมการข้าวกระทรวงเกษตรและสหกรณ์ พ.ศ. ๒๕๕๗<br />
                              วันที่ ๓๐ ธันวาคม ๒๕๕๗<br />
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>หากต้องการซื้อเมล็ดพันธุ์ข้าว จะสามารถหาซื้อได้จากที่ไหน ? </Accordion.Header>
                            <Accordion.Body>
                              ศูนย์เมล็ดพันธุ์ข้าวใกล้บ้านของท่าน หรือติดต่อสอบถามเพิ่มเติมที่ <a href='http://brs.ricethailand.go.th/index.php/2016-05-01-04-43-26'>http://brs.ricethailand.go.th/index.php/2016-05-01-04-43-26</a> โดยเลือกภูมิภาคที่ท่านอาศัยอยู่ผ่านเบอร์ติดต่อของศูนย์เมล็ดพันธุ์ข้าวต่างๆ
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>จะหาข้อมูล คลังความรู้งานวิจัยข้าวไทย ได้จากช่องทางไหน?</Accordion.Header>
                            <Accordion.Body>
                              สามารถค้นหาข้อมูลได้จาก ห้องสมุดข้าว กองวิจัยและพัฒนาข้าว <a href='http://library.ricethailand.go.th/'>http://library.ricethailand.go.th/</a>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Footer type={'form'} />
            </>
          }
        />
      </GoogleReCaptchaProvider>
    </>
  )
}

export default FormPage
