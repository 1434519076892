import React, { } from "react"
import { Container, Row, Tab, Tabs } from 'react-bootstrap'
import { useCookies } from "react-cookie"
import Content7 from "../contents/content-7"

// แบบแบนเนอร์และเอกสาร
const Layout13 = ({ content }) => {

    const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])
    return (
        <div>
            <Container className="section-box">
                <h2 className='news-section-header-with-line'>{content['mc_name_' + cookies.language]}</h2>
                <Content7 paId={content['pa_id']} />
            </Container>
        </div>
    )
}

export default Layout13
