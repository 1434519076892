import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faPen } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'

const PRNewsCard = ({ headline, link, shortDescription, author, dateText }) => {

    return (
        <Row>
            <Col xs="12" className='news-thumbnail-box'>
                <p className='pr-news-thumbnail-headline'><a href={link}>{headline}</a></p>
                <p className='pr-news-thumbnail-description'>{shortDescription}</p>
                <p className='pr-news-thumbnail-attribute'>
                    <FontAwesomeIcon size="xs" icon={faPen} className='text-normal-grey' /> <strong>{author}</strong> &nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon size="xs" icon={faCalendar} className='text-normal-grey' /> <strong>{dayjs(dateText).add(7,'hour').format("DD/MM/YYYY HH:MM")}</strong>
                </p>
            </Col>
        </Row>
    )
}

export default PRNewsCard