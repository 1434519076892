import { useEffect, useState } from "react";
import { useCookies } from "react-cookie"

const SubdomainProvider = ({ children }) => {

    const [cookies, setCookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])

    let sub = window.location.host.split('.')[0]

   

    useEffect(() => {
        if (sub === "www") {
            setCookies("subdomain", "rd")
        } else if (sub === "riceweb-staging-1") {
            setCookies("subdomain", "rd")
        } else if (sub === "riceweb-staging-2") {
            setCookies("subdomain", "ictc")
        } else if (sub === "riceweb-staging-3") {
            setCookies("subdomain", "nrm-rsc")
        } else if (sub === "ricethailand") {
            setCookies("subdomain", "rd")
        } else if (sub === "localhost:3000") {
            setCookies("subdomain", "rd")
        } else {
            setCookies("subdomain", sub)
        }

    }, [cookies.subdomain])

    return (
        <>
            {children}
        </>
    )
}

export default SubdomainProvider
