import React, { } from "react"
import { Container, Row, Col } from 'react-bootstrap'
import { useCookies } from "react-cookie"
import MenuCard from "../../components/atoms/menu-card"

// แบบมีหัวข้อย่อยแบบใหญ่
const Layout8 = ({ content }) => {

    const [cookies] = useCookies(['textSize', 'colorMode', 'language', 'subdomain', 'cookie_accepted', 'showEntersite', 'siteId'])


    return (
        <div>
            <Container className="section-box">
                <h2 className='news-section-header-with-line'>{content['mc_name_' + cookies.language]}</h2>
                <Row className="section-box">
                    {
                        content['sub_mc']?.map((sub, index) => {
                            return (
                                <Col key={'menu2' + sub['mc_id']} xs={4}>
                                    <a href={sub['mc_link']}>
                                        <MenuCard name={sub['mc_name_' + cookies.language]} imgurl={sub['mc_img']} />
                                    </a>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div >
    )
}

export default Layout8
