import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

const PersonCard = ({ data }) => {

    return (
        <Row className='news-thumbnail'>
            <Col xs="12">
                <img className='news-img-card' src={data['pic']} />
            </Col>
            <Col xs="12" className='news-thumbnail-box'>
                <p className='personnel-thumbnail-name'>{data['pre_name']}{data['f_name']} {data['l_name']}</p>
                <p className='personnel-thumbnail-position '>{data['position']}</p>
                <p className='personnel-thumbnail-position '>{data['position2']}</p>
                <p className='news-thumbnail-attribute'>
                    <FontAwesomeIcon size="xs" icon={faPhone} className='personnel-thumbnail-position' /> <strong className='personnel-thumbnail-position'>{data['tel'] !== "" ? data['tel'] : "-"}</strong> <br />
                    <FontAwesomeIcon size="xs" icon={faEnvelope} className='personnel-thumbnail-position' /> <strong className='personnel-thumbnail-position'>{data['email'] !== "" ? data['email'] : "-"}</strong>
                </p>
            </Col>
        </Row>
    )
}

export default PersonCard