import { Col, Row } from 'react-bootstrap'

const MenuCard = ({ imgurl, name }) => {

    return (
        <Row className='news-thumbnail'>
            <Col xs="12">
                <img className='news-img-card' src={imgurl} />
            </Col>
            <Col xs="12" className='news-thumbnail-box'>
                <p className='menu-card-name'>{name}</p>
            </Col>
        </Row>
    )
}

export default MenuCard